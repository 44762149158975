import * as React from 'react'

import Seo from '../components/seo'
import SectionDefault from '../components/sectionDefault'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <SectionDefault>
    <Seo title="404: Not found" />
    <div className={`flex items-center h-screen`}>
      <div className={`prose`}>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness 🙄</p>
        <p>
          <Link to={`/`}>Home</Link>
        </p>
      </div>
    </div>
  </SectionDefault>
)

export default NotFoundPage
